// 观看广告视频
import { commonReduxGenerator } from '@/util/reduxGenerator/index'
import { deviceApi } from '@/config/api'

export const {
  reducer,
  SUCCESS,
  ERROR,
  LOADING,
  LOADED,
  RESET,
  skipMarket
} = commonReduxGenerator({
  type: 'market',
  actionName: 'skip',
  queryType: 'device',
  api: deviceApi.skipMarket
})
