// 获取app列表
import { commonReduxGenerator } from '@/util/reduxGenerator'
import { api } from '@/config/api'

export const {
  reducer,
  SUCCESS,
  ERROR,
  LOADING,
  LOADED,
  RESET,
  getApp
} = commonReduxGenerator({
  type: 'app',
  api: api.app,
  state: {
    data: {
      list: [],
      style: ''
    }
  }
})
