import React, { createElement } from 'react'
import Loadable from 'react-loadable'
import { Button, Spin } from 'antd'

const dynamicWrapper = (Component) => {
  return Loadable({
    loader: () => {
      return (Component)().then((v) => {
        return (props) => {
          return createElement((v).default || v, { ...props })
        }
      })
    },

    loading: ({
      error,
      retry,
      pastDelay
    }) => {
      if (error) {
        return (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              padding: '100px 0'
            }}
          >
            <span>
              加载失败了:( <Button onClick={retry}>重试</Button>
            </span>
          </div>
        )
      }

      if (pastDelay) {
        return (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              padding: '100px 0'
            }}
          >
            <Spin size='default' />
          </div>
        )
      }

      return null
    }
  })
}

const routerConfig = [
  {
    path: '/index',
    name: '精品推荐',
    component: dynamicWrapper(() => import('@/page/index'))
  },
  {
    path: '/auth',
    name: '应用权限',
    component: dynamicWrapper(() => import('@/page/auth'))
  }
]

export default routerConfig
