const api = {
  app: 'transaction/index',
  statistics: 'transaction/statistics',
}

const deviceApi = {
  skipMarket: 'skipMarket'
}

export {
  api,
  deviceApi
}