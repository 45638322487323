import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { createHashHistory } from 'history'
import { routerReducer, routerMiddleware } from 'react-router-redux'

import { reducer as app } from './app'
import { reducer as status } from './status'
import { reducer as skip_market } from './market'
import { reducer as submit_statistics } from './statistics'

export const history = createHashHistory()

const store = createStore(combineReducers({
  app,
  status,
  skip_market,
  submit_statistics,

  router: routerReducer
}), compose(
  applyMiddleware(
    thunk,
    routerMiddleware(history)
  ),
  process.env.NODE_ENV === 'development' && (window).__REDUX_DEVTOOLS_EXTENSION__ ? (window).__REDUX_DEVTOOLS_EXTENSION__() : compose()
))

export default store
