import _ from 'lodash'
import immer from 'immer'

export default ({
                  type = '',
                  state = [],
                  actionArr = {}
                }) => {
  const initState = {
    loading: false,
    msg: '',
    
    ...state
  }
  
  const ACTION_SUCCESS = `${ type }/SUCCESS`
  const ACTION_ERROR = `${ type }/ERROR`
  const ACTION_RESET = `${ type }/RESET`
  const ACTION_LOADING = `${ type }/LOADING`
  const ACTION_LOADED = `${ type }/LOADED`
  
  const actionMap = {
    [ACTION_SUCCESS]: (state, payload) => immer(state, draft => {
      if (!payload) {
        return
      }
      
      Object.keys(payload).forEach(k => {
        draft[k] = Object.assign({}, state[k], payload[k])
      })
      
      draft.msg = ''
      draft.loading = false
    }),
    [ACTION_ERROR]: (state, payload) => immer(state, draft => {
      draft.msg = payload
      draft.loading = false
    }),
    [ACTION_LOADING]: (state, payload) => immer(state, draft => {
      draft.loading = true
    }),
    [ACTION_LOADED]: (state, payload) => immer(state, draft => {
      draft.loading = false
    }),
    [ACTION_RESET]: (state, payload) => initState,
    
    ...actionArr
  }
  
  const SUCCESS = (payload) => ({ payload, type: ACTION_SUCCESS })
  const ERROR = (payload) => ({ payload, type: ACTION_ERROR })
  const LOADING = (payload) => ({ payload, type: ACTION_LOADING })
  const LOADED = (payload) => ({ payload, type: ACTION_LOADED })
  const RESET = (payload) => ({ payload, type: ACTION_RESET })
  
  return {
    reducer: (state = initState, action) => {
      const {
              type,
              payload
            } = action
    
      if (type in actionMap) {
        return actionMap[type](state, payload)
      }
    
      return state
    },
    SUCCESS,
    ERROR,
    LOADING,
    LOADED,
    RESET
  }
}
