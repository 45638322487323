import axios from '@/util/axios'
import _ from 'lodash'
import immer from 'immer'

import { getName } from './util'

export default ({
  actionName = 'get',
  queryType = 'post',
  type = '',
  state = [],
  actionArr = {},

  api = '',
  initParams = {},

  format,
  motion = {},
  rsKey = ''
}) => {
  const initState = {
    init: false,
    loading: false,
    msg: '',
    query: null,
    data: {},

    ...state
  }

  const typeName = actionName === 'get' ? type : actionName + '_' + type

  const ACTION_SUCCESS = typeName + '/SUCCESS'
  const ACTION_ERROR = typeName + '/ERROR'
  const ACTION_LOADING = typeName + '/LOADING'
  const ACTION_LOADED = typeName + '/LOADED'
  const ACTION_RESET = typeName + '/RESET'

  const actionMap = {
    [ACTION_SUCCESS]: (state, payload) => immer(state, draft => {
      if (!payload) {
        return
      }

      Object.keys(payload).forEach(k => {
        draft[k] = payload[k]
      })

      draft.msg = ''
      draft.loading = false
    }),
    [ACTION_ERROR]: (state, payload) => immer(state, draft => {
      draft.msg = payload
      draft.loading = false
    }),
    [ACTION_LOADING]: (state, payload) => immer(state, draft => {
      draft.loading = true
    }),
    [ACTION_LOADED]: (state, payload) => immer(state, draft => {
      draft.loading = false
    }),
    [ACTION_RESET]: (state, payload) => initState,

    ...actionArr
  }

  const SUCCESS = (payload) => ({ payload, type: ACTION_SUCCESS })
  const ERROR = (payload) => ({ payload, type: ACTION_ERROR })
  const LOADING = (payload) => ({ payload, type: ACTION_LOADING })
  const LOADED = (payload) => ({ payload, type: ACTION_LOADED })
  const RESET = (payload) => ({ payload, type: ACTION_RESET })

  return {
    reducer: (state = initState, action) => {
      const {
        type,
        payload
      } = action

      if (type in actionMap) {
        return actionMap[type](state, payload)
      }

      return state
    },
    SUCCESS,
    ERROR,
    LOADING,
    LOADED,
    RESET,
    [getName(type, actionName)]: (params = {}, ignore = true) => {
      return async (dispatch, getStore) => {
        const {
          [actionName === 'get' ? type : actionName + '_' + type]: {
            query,
            data
          }
        } = getStore()

        const assignParams = { ...params, ...initParams }

        if (!ignore && _.isEqual(query, assignParams)) {
          return [null, data]
        }

        dispatch(LOADING())

        const [err, res] = await axios[queryType](api, queryType === 'get' ? { params: assignParams } : assignParams)

        dispatch(LOADED())

        // 特殊情况的数据处理
        if (format instanceof Function) {
          return format({ rt: [err, res], dispatch, assignParams })
        }

        if (err) {
          dispatch(ERROR(err))
          return [err]
        }

        const _data = rsKey ? res[rsKey] : res

        dispatch(
          SUCCESS({
            init: true,
            query: assignParams,
            data: _data
          })
        )

        return [null, _data]
      }
    },
    ...motion
  }
}
