import { stringify } from 'qs'

const device = getDevice()

function getDevice () {
  const u = navigator.userAgent
  const isWx = u.indexOf('MicroMessenger') > -1 // 是否微信
  const isQQ = u.match(/\sQQ/i) === ' qq' // 是否QQ

  return {
    include: u.indexOf('NTWeather') !== -1,
    special: u.indexOf('NTWeather_v1') !== -1,
    system: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ? 'ios' : 'android',
    isWx,
    isQQ
  }
}

function sendMessage (method, info) {
  const { system } = device

  if (system === 'ios') {
    if (window && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[method]) {
      window.webkit.messageHandlers[method].postMessage(info)
    }
  }

  if (system === 'android') {
    if (window && window.androidWeb && window.androidWeb[method]) {
      window.androidWeb[method](JSON.stringify(info))
    }
  }
}

function initChat () {
  function JSBridgeCore () {
    this.uniqueId = 1
    this.callbackCache = {}
  }

  /**
   * 由 JS 调用 Natvie 方法
   */
  JSBridgeCore.prototype.callNative = function (action, args, successCallback, failCallback) {
    const url = action + '?' + stringify({ jsonParams: JSON.stringify(args) })

    const message = {
      action: action,
      params: { url },
      callbackId: null
    }
    console.log(url)
    if (successCallback || failCallback) {
      const callbackId = action + '_' + (this.uniqueId++) + '_' + new Date().getTime()

      this.callbackCache[callbackId] = { success: successCallback, fail: failCallback }
      message.callbackId = callbackId
    }

    if (device.system === 'ios') {
      if (window && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['handleMessageFromJS']) {
        window.webkit.messageHandlers['handleMessageFromJS'].postMessage(message)
      } else {
        failCallback && failCallback('当前版本不支持,请尽快升级最新版本')
      }
    }

    if (device.system === 'android') {
      if (window && window.androidWeb && window.androidWeb['handleMessageFromJS']) {
        window.androidWeb['handleMessageFromJS'](JSON.stringify(message))
      } else {
        failCallback && failCallback('当前版本不支持,请尽快升级最新版本')
      }
    }
  }

  /**
   * 由 Native 调用 JS 方法传回结果
   */
  JSBridgeCore.prototype.handleMessageFromNative = function (messageString) {
    const callbackMessage = JSON.parse(messageString)
    const { status, callbackId } = callbackMessage
    const callback = this.callbackCache[callbackId]
    console.log(callbackMessage)
    if (callback) {
      if (status === 0) {
        callback.fail(callbackMessage.data)
      } else {
        callback.success(callbackMessage.data)
      }

      this.callbackCache[callbackMessage.callbackId] = null
      delete this.callbackCache[callbackMessage.callbackId]
    }
  }

  window.JSBridgeCore = new JSBridgeCore()
}

export default initChat
export {
  device,
  getDevice,
  sendMessage
}
