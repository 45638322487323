// 提交统计代码
import { commonReduxGenerator } from '@/util/reduxGenerator'
import { api } from '@/config/api'

export const {
  reducer,
  SUCCESS,
  ERROR,
  LOADING,
  LOADED,
  RESET,
  submitStatistics
} = commonReduxGenerator({
  type: 'statistics',
  api: api.statistics,
  actionName: 'submit'
})
