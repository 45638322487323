import React from 'react'
import {
  HashRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import BasicLayout from '@/layout/basic'

export default function App () {
  return (
    <Router >
      <Switch >
        <StatsWrap>
          <Route path='/' component={BasicLayout} />
        </StatsWrap>
      </Switch >
    </Router >
  )
}

// 百度/友盟统计路由拦截器
const StatsWrap = props => {
  (function () {
    const {
      location: {
        search = '',
        pathname = ''
      }
    } = props

    if (pathname === '/') {
      return
    }

    window._hmt.push(['_trackPageview', pathname + search])
  })()

  return props.children
}

