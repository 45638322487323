import _ from 'axios'
import { codeMessage, CODE } from './config'

const device_root = 'nineton://'

const axios = _.create({
  baseURL: process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_PROXY_KEY
    : process.env.REACT_APP_PROXY,
  headers: {
    'Content-Type': 'application/json'
  }
})

// 发送拦截器
axios.interceptors.request.use(
  config => {
    const { data, params } = config

    if (config.method === 'post') {
      config.data = { ...data }
    }

    if (config.method === 'get') {
      config.params = { ...params }
    }

    return config
  }, error => [error.toString()]
)

// 接收拦截器
axios.interceptors.response.use(
  res => {
    const {
      status,
      data: {
        code,
        info = '请求错误',
        data
      }
    } = res

    if (status !== 200) {
      return [`连接失败 code:${status}`]
    }

    if (code === CODE.redirect) {
      window.location.replace(data['joker_url'])
      return
    }

    if (code !== CODE.success) {
      return [info]
    }

    return [null, data]
  }, error => {
    const { response } = error

    if (!response) {
      return ['未连接到服务器']
    }

    if (response && response.status) {      // 还是需要返回错误原信息,因为接口需要传递发送信息与接口的信息
      return [codeMessage[response.status]]
    }
  }
)

axios.device = (url, params = {}) => {
  return new Promise((r) => {
    let _url = url

    if (!url.startsWith(device_root)) {
      _url = device_root + url
    }

    window.JSBridgeCore.callNative(_url, params, res => {
      r([null, res])
    }, err => {
      r([err || '服务器开小差了，请稍后再试'])
    })
  })
}

export default axios



