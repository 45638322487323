import 'babel-polyfill'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'

import App from './App'
import moment from 'moment'
import 'moment/locale/zh-cn'

import store, { history } from './model'

import initChat from '@/util/initChat'

// import VC from 'vconsole'

/** 样式 **/
import './style/index.scss'

moment.locale('zh-cn')

// new VC()

/** 初始化Android/Ios通信方法 **/
initChat()

render(
  <Provider store={store} >
    <ConnectedRouter history={history} >
      <App />
    </ConnectedRouter >
  </Provider >,
  document.getElementById('root')
)

