// 页面中需要缓存的状态
import { basicReduxGenerator } from '@/util/reduxGenerator/index'

export const {
  reducer,

  SUCCESS,
  ERROR,
  LOADING,
  LOADED,
  RESET
} = basicReduxGenerator({
  type: 'status',
  state: {
    topStatus: {}
  }
})
