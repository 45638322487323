import React, { Component } from 'react'
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import cs from 'classnames'

import routerConfig from '@/common/router'

import style from './index.module.scss'

class App extends Component {
  render() {
    const {
      className,
      onRef
    } = this.props

    const classStr = cs(style['basic'], className)

    return (

      <div
        id='basic'
        ref={ el => { onRef && onRef(el) } }
        className={ classStr }
      >
        <Switch>
          {
            routerConfig.map(v => (
              <Route path={ v.path } key={ v.path } component={ v.component } exact />
            ))
          }
          <Redirect from='/' to='/index' exact />
        </Switch>
        </div >
    )
  }
}

export default App
